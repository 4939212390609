<template>
    <b-container fluid style="max-width:25rem; margin:auto; text-align:center">
        <b-form v-on:submit="handleFormSubmit">
            <b-radio-group buttons button-variant="outline-secondary" size="lg" :plain="true" checked="PUNCH_IN">
                <b-radio :hidden="true" v-model="selected" value="PUNCH_IN" checked="PUNCH_IN">
                I'm clocking In
                </b-radio>
                <b-radio :hidden="true" v-model="selected" value="PUNCH_OUT">
                I'm clocking Out
                </b-radio>
            </b-radio-group>
            
            <b-form-group>

                <div class="my-5">
                    <b-form-input autocomplete="off" :state="isValidEntry" v-model="pinText" :required="true" placeholder="Enter Site Pin" size="lg" style="max-width:20rem; margin:auto"></b-form-input>
                    <label class="my-1">{{siteInfo}}</label>
                </div>
                
                <b-form-invalid-feedback>
                    <p>The PIN you've provided is not valid:</p>
                    <p>Please try again</p>
                </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
                <b-form-input autocomplete="off" v-model="userName" :required="true" placeholder="Your Full Name" class="my-3" size="lg" style="max-width:20rem; margin:auto"></b-form-input>
            </b-form-group>
            <b-form-group>
                <b-form-input v-model="locationText" :state="isValidGpsState" plaintext placeholder="GPS Permission Required" class="my-3" style="max-width:20rem; margin:auto; text-align:center">
                
                </b-form-input>
                
                <b-form-invalid-feedback>
                    {{goelocationErrorMessage}}
                    <router-link to="/help">Help! How do I enable this?</router-link>
                </b-form-invalid-feedback>
            </b-form-group>
            <div>
                <label v-if="!isNetworkBusy">{{clockedStatusText}}</label>
            </div>
            <b-button 
                size="lg" 
                type="submit" 
                :disabled="isNetworkBusy" 
                variant="primary" 
                class="my-3">
                <b-spinner :hidden="!isNetworkBusy" small></b-spinner>
                {{clockButtonText}}
            </b-button>
            <!-- <b-toast id="success-toast" title="Success" no-close-button :auto-hide-delay="100000">
                You've finished {{clockingInText}}
            </b-toast> -->
            <b-alert
                class="position-fixed fixed-bottom m-0 rounded-0"
                style="z-index: 2000;"
                variant="success"
                fade
                :show="dismissCountdown"
                >
                Successfully finished {{clockingInText}}
            </b-alert>

        </b-form>
        <div :hidden="showClockTypeMessage" style="color:red">{{clockTypeText}}</div>
    </b-container>
</template>

<script>
import siteService from './SiteService'
let keyName = "com.fall-soft.timeclock.user.name";
let keyClockType = "com.fall-soft.timeclock.type";
let keyPunchId = "com.fall-soft.timeclock.punch.id";

export default {
    name: 'TimeClock',
    props: {
    },
    data() {
        return { 
            selected: "PUNCH_IN",
            isValidEntry: null,
            pinText: "",
            location: null,
            isGeolocationDeviceSupported: true,
            userName: "",
            debugMsg: "",
            isNetworkBusy: false,
            dismissCount: 5,
            dismissCountdown: 0,
            showClockTypeMessage: false,
            siteInfo: "",
            showClockedStatus: false
        }
    },
    mounted: function() {
        let nameResult = window.localStorage.getItem(keyName)
        let clockResult = window.localStorage.getItem(keyClockType);
        let clockId = window.localStorage.getItem(keyPunchId);

        if (nameResult) {
            this.userName = nameResult;
        }

        if (clockResult) {
            this.selected = clockResult == "PUNCH_IN" ? "PUNCH_OUT" : "PUNCH_IN"
        }

        if (clockId) {
            this.showClockedStatus = true;
        }
        
        if (!("geolocation" in navigator)) {
            this.isGeolocationDeviceSupported = false;
        }

        navigator.geolocation.getCurrentPosition((pos) => {
            this.location = pos.coords
            console.log(pos.coords)
        }, (err) => {
            this.location = null
            console.log(err)
        });
    },
    watch: {
        pinText: function(val) {
            if (val) {
                if (val.length == 4) {
                    siteService.getSiteInfo(val).then(response => {
                        this.siteInfo = `${response.data.siteName} : ${response.data.vendorName}`;
                        this.isValidEntry = null;
                    }).catch(() => {
                        this.siteInfo = "";
                        this.isValidEntry = false
                    });
                }
            }
        }
    },
    methods: {
        handleFormSubmit(event) {
            event.preventDefault()
            this.debugMsg = "Submitting Form";
            let jsonBody = {
                latitude: this.location.latitude,
                longitude: this.location.longitude,
                sitePin: this.pinText,
                userName: this.userName,
                punchType: this.selected,
            }

            if (this.selected == "PUNCH_OUT") {
                jsonBody.id = window.localStorage.getItem(keyPunchId);
            }
 
            window.localStorage.setItem(keyName, this.userName);
            window.localStorage.setItem(keyClockType, this.selected);

            this.isNetworkBusy = true;

            siteService.createSitePunch(jsonBody)
            .then((data) => {
                console.log(data)
                this.debugMsg = "Recieved Data"
                this.isValidEntry = true;
                this.dismissCountdown = this.dismissCount;
                
                if (jsonBody.punchType == "PUNCH_IN") {
                    window.localStorage.setItem(keyPunchId, data.id);
                    this.showClockedStatus = true
                } else {
                    window.localStorage.removeItem(keyPunchId);
                    this.showClockedStatus = false
                }
            })
            .catch((err) => {
                console.log(err)
                this.debugMsg = `Recieved Error ${err.cause.response.status}`
                if (err.cause.response.status == 404) {
                    this.isValidEntry = false;
                }
            })
            .finally(() => {
                this.isNetworkBusy = false;
            });  
        },
        calculateClockedStatusText() {
            let id = window.localStorage.getItem(keyPunchId);
            
            if (id != null) {
                return "Status: You Are Clocked In";
            } else {
                return "";
            }
        },
  },
  computed: {
      clockButtonText() {
          return this.selected == "PUNCH_IN" ? "Clock In" : "Clock Out"
      },
      clockedStatusText() {
          if (this.showClockedStatus) {
              return this.calculateClockedStatusText();
          } else {
              return ""
          }
      },
      isValidGpsState() {
            return this.location != null;
      },
      goelocationErrorMessage() {
          if (this.isGeolocationDeviceSupported) {
              return "Please allow GPS Permissions on your browser and refresh the page to continue."
          } else {
              return "A device with GPS support is required"
          }
      },
      locationText() {
          if (this.location) {
              return `Location: <${this.location.latitude}, ${this.location.longitude}>`
          }

          return ""
      },
      clockingInText() {
           return this.selected == "PUNCH_IN" ? "clocking In" : "clocking Out"
      },
      clockTypeText() {
          let id = window.localStorage.getItem(keyPunchId);
          if (this.selected == "PUNCH_OUT") {
              if (id == null) {
                  return "Please clock in first";
              } else {
                  return "";
              }
          } else { // Selected Punch In
              if (id != null) {
                  return "Please Clock OUT first"
              } else {
                  return ""
              }
          } 
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
