<template>
    <div class="m-2">
        <router-link to="/clock">Back to Time-Clock</router-link>
        <h3 class="tm-color-heading">Geolocation Help</h3>
        <b-tabs>
            <b-tab title="Android" style="text-align:left">
                <h5 class="tm-bottom-seperator m-2">Android 10 &amp; 11</h5>
                <ol>
                    <li>Swipe down from the top of the screen.</li>
                    <li>
                        Touch and hold Location. If you don't see Location tap Edit (Pencil icon)  or Settings (Cogwheel). Drag Location into your Quick Settings.
                    </li>
                </ol>
                <p class="my-4">To turn your phone's location accuracy on:</p>
                <ol>
                    <li>
                        Swipe down from the top of the screen. 
                    </li>
                    <li>
                        Touch and hold Location.
                    </li>
                    <li>
                        Tap Advanced Google > Location Accuracy. 
                    </li>
                    
                    <li>
                        Turn Improve Location Accuracy on or off.
                    </li>
                
                </ol>
                <a href="https://support.google.com/android/answer/3467281?hl=en">Still Stuck? Read more about location services on android</a>
            </b-tab>
            <b-tab title="iOS" style="text-align:left">
                <h5 class="tm-bottom-seperator m-2">1. Enable Location Services</h5>
                <div class="mx-4">
                    <p>Open the settings app and navigate to:</p>
                    <p>Settings > Privacy > Location</p>
                    <p>Scroll and Select <b>Safari Websites</b> and ensure location access is set to "While Using the App"</p>
                </div>
                <h5 class="tm-bottom-seperator mx-2">2. Enable in your Browser (Safari, Chrome, etc...)</h5>
                <div class="mx-4">
                    <p>Open settings and navigate to:</p>
                    <p>Settings > Safari (or other browser)</p>
                    <p>Scroll to Location</p>
                    <p>Choose either "Ask" or "Allow"</p>
                </div>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>

export default {
    name: "GeolocationHelp"
}
</script>

<style scoped>

</style>